





























































import { Component, Vue, Prop } from "vue-property-decorator";

import SidebarForm from "../SidebarForm.vue";
import InvoiceCalculationRequestModel from "@/accounting-portal/models/invoices/InvoiceCalculationRequestModel";
import CounterpartyShortResponseModel from "@/accounting-portal/models/counterparties/CounterpartyShortResponseModel";
import ValidUtil from "@/shared/utils/ValidUtil";
import DateUtil from "@/shared/utils/DateUtil";

@Component({
  components: {
    SidebarForm,
  },
})
export default class InvoiceCalculationForm extends Vue {
  @Prop({ default: false }) value!: boolean;

  readonly REQUIRED_RULE = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  localValue = new InvoiceCalculationRequestModel();
  accrualDateVisible = false;

  get visible(): boolean {
    return this.value;
  }

  set visible(value: boolean) {
    if (!value) {
      this.localValue = new InvoiceCalculationRequestModel();
    } else {
      this.$store.dispatch("loadShortCounterparties");
    }

    this.$emit("input", value);
  }

  get isCounterpartiesLoading(): boolean {
    return this.$store.state.counterpartyStore.isCounterpartiesLoading;
  }

  get counterparties(): Array<{ text: string; value: number }> {
    return this.$store.state.counterpartyStore.shortCounterparties.map(
      ({ id, name }: CounterpartyShortResponseModel) => ({
        text: name,
        value: id,
      })
    );
  }

  get isInvoiceSaving(): boolean {
    return this.$store.state.invoiceStore.isInvoiceSaving;
  }

  get maxDate(): string {
    return DateUtil.startOfLastMonth(DateUtil.today());
  }

  async handleSave(closeDialog: () => void) {
    await this.$store.dispatch("createInvoice", this.localValue);

    this.localValue = new InvoiceCalculationRequestModel();
    closeDialog();
  }
}
