



















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ExpansionPanelHeader extends Vue {
  @Prop() value!: Array<number>;
  @Prop() index!: number;

  get isPanelOpen(): boolean {
    return this.value.includes(this.index);
  }
}
