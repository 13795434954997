






































import { Component, Vue } from "vue-property-decorator";

import InvoicesTable from "./InvoicesTable.vue";
import InvoiceResponseModel, {
  InvoiceStatus,
} from "@/accounting-portal/models/invoices/InvoiceResponseModel";

@Component({
  components: {
    InvoicesTable,
  },
})
export default class InvoicesResult extends Vue {
  readonly InvoiceStatus = InvoiceStatus;
  readonly TABS = [
    {
      value: InvoiceStatus.OPEN,
      label: this.$lang("accountingPortal.invoices.open"),
    },
    {
      value: InvoiceStatus.PAID,
      label: this.$lang("accountingPortal.invoices.paid"),
    },
    {
      value: InvoiceStatus.VALIDATED,
      label: this.$lang("accountingPortal.invoices.validated"),
    },
  ];
  activeTab = InvoiceStatus.OPEN;

  getInvoicesCountByStatus(status: InvoiceStatus): string {
    return String(
      this.$store.state.invoiceStore.invoices.filter(
        (item: InvoiceResponseModel) => item.status === status
      ).length
    );
  }
}
