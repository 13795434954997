












































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import InvoiceChangeHistoryResponseModel from "@/accounting-portal/models/invoices/InvoiceChangeHistoryResponseModel";
import InvoiceResponseModel, {
  InvoiceStatus,
} from "@/accounting-portal/models/invoices/InvoiceResponseModel";
import DateUtil from "@/shared/utils/DateUtil";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

@Component
export default class InvoiceDetailsDialog extends mixins(
  AppSectionAccessMixin
) {
  @Prop({ default: false }) value!: boolean;
  @Prop() invoice!: InvoiceResponseModel | null;

  readonly DateUtil = DateUtil;
  readonly InvoiceStatus = InvoiceStatus;
  readonly CHANGE_HISTORY_HEADERS = [
    {
      text: this.$lang("accountingPortal.invoices.fullName"),
      value: "author",
    },
    {
      text: this.$lang("accountingPortal.invoices.date"),
      value: "date",
    },
    {
      text: this.$lang("accountingPortal.invoices.changes"),
      value: "changes",
      sortable: false,
    },
    {
      text: this.$lang("accountingPortal.invoices.status"),
      value: "status",
    },
  ];

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get visible(): boolean {
    return this.value;
  }

  set visible(value: boolean) {
    this.$emit("input", value);
  }

  get changeHistoryItems(): Array<InvoiceChangeHistoryResponseModel> {
    return this.$store.state.invoiceStore.invoiceChangeHistory;
  }

  get loadingChangeHistory(): boolean {
    return this.$store.state.invoiceStore.isInvoiceChangeHistoryLoading;
  }

  @Watch("visible")
  watchInvoice() {
    if (this.visible && this.invoice) {
      this.$store.dispatch("loadInvoiceChangeHistory", this.invoice.id);
    }
  }

  handleClose() {
    this.visible = false;
  }

  getStatusColor({ status }: InvoiceChangeHistoryResponseModel): string {
    return {
      EDITED: "orange",
      CREATED: "green",
    }[status];
  }
}
