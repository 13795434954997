







































import { Component, Vue, Prop, Ref, Watch } from "vue-property-decorator";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {
  InvoiceChartType,
  InvoiceExpectedDateStatus,
  InvoicePaymentStatus,
} from "@/accounting-portal/models/invoices/InvoiceResponseModel";

@Component
export default class InvoiceTotalChartCard extends Vue {
  @Prop() title!: string;
  @Prop() data!: Array<Record<string, any>>;
  @Prop() type!: InvoiceChartType;

  @Ref() chartElement!: HTMLElement;

  root: am5.Root | null = null;

  get isEmptyData(): boolean {
    return this.data.every(({ value }) => !value);
  }

  get textForEmptyData(): string {
    if (this.isEmptyData && this.type === InvoiceChartType.PAYMENT_STATUS) {
      return this.$lang("accountingPortal.invoices.paymentStatusEmptyData");
    }

    if (
      this.isEmptyData &&
      this.type === InvoiceChartType.EXPECTED_DATE_STATUS
    ) {
      return this.$lang(
        "accountingPortal.invoices.expectedDateStatusEmptyData"
      );
    }

    return "";
  }

  @Watch("data", { deep: true, immediate: true })
  async watchData() {
    if (!this.isEmptyData) {
      await this.$nextTick();
      this.initChart();
    }
  }

  getBackgroundForStatus(
    status: InvoicePaymentStatus | InvoiceExpectedDateStatus
  ): string {
    return {
      OVERPAY: "amber lighten-1",
      UNDERPAY: "red lighten-1",
      OK: "green",
      OVERDUE: "red lighten-1",
    }[status];
  }

  initChart() {
    if (this.root) {
      this.root.dispose();
    }

    this.root = am5.Root.new(this.chartElement);
    this.root.setThemes([am5themes_Animated.new(this.root)]);
    const chart = this.root.container.children.push(
      am5percent.PieChart.new(this.root, {
        radius: am5.percent(90),
        innerRadius: am5.percent(70),
      })
    );

    const series: any = chart.series.push(
      am5percent.PieSeries.new(this.root, {
        name: "Series",
        valueField: "value",
        categoryField: "status",
      })
    );
    series.slices.template.set(
      "tooltipText",
      "{valuePercentTotal.formatNumber('0.0')}%"
    );

    series
      .get("colors")
      .set(
        "colors",
        this.type === InvoiceChartType.PAYMENT_STATUS
          ? [am5.color(0x64c84b), am5.color(0xffca28), am5.color(0xef5350)]
          : [am5.color(0x64c84b), am5.color(0xef5350)]
      );

    series.data.setAll(this.data);

    // Disabling labels and ticks
    series.labels.template.set("visible", false);
    series.ticks.template.set("visible", false);
  }
}
