

























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import SidebarForm from "../SidebarForm.vue";
import InvoiceResponseModel from "@/accounting-portal/models/invoices/InvoiceResponseModel";
import InvoiceUpdateRequestModel from "@/accounting-portal/models/invoices/InvoiceUpdateRequestModel";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    SidebarForm,
  },
})
export default class InvoiceEditDialog extends Vue {
  @Prop({ default: false }) value!: boolean;
  @Prop() invoice!: InvoiceResponseModel | null;

  readonly REQUIRED_RULE = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly REQUIRED_NUMBER_RULE = [
    ValidUtil.requiredNumber(this.$lang("validation.required")),
    ValidUtil.minNumber(1, this.$lang("validation.min", 1)),
  ];
  localValue = new InvoiceUpdateRequestModel();
  menuPaymentDateVisible = false;

  get visible(): boolean {
    return this.value;
  }

  set visible(value: boolean) {
    this.$emit("input", value);
  }

  get saving(): boolean {
    return this.$store.state.invoiceStore.isInvoiceSaving;
  }

  @Watch("invoice")
  watchInvoice(value: InvoiceResponseModel) {
    if (value) {
      this.localValue = new InvoiceUpdateRequestModel(
        value.paymentDate,
        value.amount
      );
    }
  }

  async handleSaveInvoice() {
    await this.$store.dispatch("updateInvoice", {
      id: this.invoice?.id,
      invoice: this.localValue,
    });
    this.visible = false;
  }
}
