






























import InvoiceResponseModel, {
  InvoiceStatus,
} from "@/accounting-portal/models/invoices/InvoiceResponseModel";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class InvoiceTotalCard extends Vue {
  @Prop() currencyCode!: string;
  @Prop() invoices!: Array<InvoiceResponseModel>;

  get totalAmount(): number {
    return this.invoices.reduce(
      (result: number, { expectedAmount }) => result + expectedAmount,
      0
    );
  }

  get paidAndValidatedInvoices(): Array<InvoiceResponseModel> {
    return this.invoices.filter(
      ({ status }: InvoiceResponseModel) =>
        status === InvoiceStatus.PAID || status === InvoiceStatus.VALIDATED
    );
  }

  get paidAmount(): number {
    return this.paidAndValidatedInvoices.reduce(
      (result: number, { amount }) => result + Number(amount),
      0
    );
  }

  get progress(): number {
    return (this.paidAndValidatedInvoices.length * 100) / this.invoices.length;
  }

  getFormattedAmount(amount: number): string {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: this.currencyCode,
      maximumFractionDigits: 0,
    }).format(Math.round(amount));
  }
}
