
















import { Component, Vue, Prop } from "vue-property-decorator";

import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import InvoiceFiltersModel from "@/accounting-portal/models/invoices/InvoiceFiltersModel";
import CounterpartyShortResponseModel from "@/accounting-portal/models/counterparties/CounterpartyShortResponseModel";
import { FilterPreviewId } from "@/shared/models";

@Component({ components: { CustomAutocomplete } })
export default class CounterpartiesFilterVisualization extends Vue {
  @Prop() value!: InvoiceFiltersModel;
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() isAttached!: boolean;

  get localValue(): InvoiceFiltersModel {
    return this.value;
  }

  set localValue(value: InvoiceFiltersModel) {
    this.$emit("input", value);
  }

  get items(): Array<{ text: string; value: number }> {
    return this.$store.state.counterpartyStore.shortCounterparties.map(
      ({ name, id }: CounterpartyShortResponseModel) => ({
        text: name,
        value: id,
      })
    );
  }

  get loading(): boolean {
    return this.$store.state.counterpartyStore.isCounterpartiesLoading;
  }
}
