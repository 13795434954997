





























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import DatesPicker from "@/shared/components/pickers/DatesPicker.vue";
import InvoiceFiltersModel from "@/accounting-portal/models/invoices/InvoiceFiltersModel";
import { DatePickerPresetModel, ValidationRule } from "@/shared/models";
import DateUtil from "@/shared/utils/DateUtil";
import DateFormats from "@/shared/utils/DateFormats";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    DatesPicker,
  },
})
export default class AccrualPeriodFilterVisualization extends Vue {
  @Prop() value!: InvoiceFiltersModel;

  readonly MIN_DATE = "2018-09";
  readonly DATES_SEPARATOR = " - ";
  readonly PRESETS = [
    new DatePickerPresetModel(
      this.$lang("presets.lastMonth"),
      DateUtil.formatForMonthPicker(
        DateUtil.startOfLastMonth(DateUtil.twoDaysAgo())
      ),
      DateUtil.formatForMonthPicker(
        DateUtil.endOfLastMonth(DateUtil.twoDaysAgo())
      )
    ),
    new DatePickerPresetModel(
      this.$lang("presets.thisYear"),
      DateUtil.formatForMonthPicker(
        DateUtil.startOfYear(DateUtil.twoDaysAgo())
      ),
      DateUtil.formatForMonthPicker(
        DateUtil.endOfLastMonth(DateUtil.twoDaysAgo())
      )
    ),
    new DatePickerPresetModel(
      this.$lang("presets.lastYear"),
      DateUtil.formatForMonthPicker(
        DateUtil.startOfLastYear(DateUtil.twoDaysAgo())
      ),
      DateUtil.formatForMonthPicker(
        DateUtil.endOfLastYear(DateUtil.twoDaysAgo())
      )
    ),
    new DatePickerPresetModel(
      this.$lang("presets.allTime"),
      this.MIN_DATE,
      DateUtil.formatForMonthPicker(
        DateUtil.endOfLastMonth(DateUtil.twoDaysAgo())
      )
    ),
  ];
  menu = false;
  dateFormatted = "";
  localDatesValue: Array<string> = [];

  get requiredRule(): Array<ValidationRule> {
    return !this.value.paymentDateFrom && !this.value.paymentDateTo
      ? [ValidUtil.required(this.$lang("validation.required"))]
      : [];
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get accrualMonthFrom(): string {
    return this.value.accrualMonthFrom ?? "";
  }

  get accrualMonthTo(): string {
    return this.value.accrualMonthTo ?? "";
  }

  get accrualPeriod(): Array<string> {
    return [this.accrualMonthFrom, this.accrualMonthTo];
  }

  get valid(): boolean {
    return this.localDatesValue.every((value) => value !== "Invalid date");
  }

  get inputErrorMessage(): Array<string> {
    const arrayFromLocalValueText = this.dateFormatted
      ? this.dateFormatted
          .split(this.DATES_SEPARATOR)
          .filter((item: string) => !!item)
      : [];
    const hasInvalidDate = arrayFromLocalValueText.some(
      (item) =>
        !DateUtil.isValidDate(DateUtil.formatFromDefaultToMonthPicker(item))
    );

    if (arrayFromLocalValueText.length === 1) {
      return [this.$lang("validation.required")];
    }

    if (hasInvalidDate) {
      return [this.$lang("validation.invalidDate")];
    }

    return [];
  }

  get maxDate(): string {
    return DateUtil.startOfLastMonth(DateUtil.today());
  }

  @Watch("accrualPeriod", { immediate: true })
  watchAccrualPeriod(value: Array<string>) {
    if (value[0] || value[1]) {
      this.localDatesValue = [value[0], value[1]];
    }
  }

  @Watch("localDatesValue", { immediate: true, deep: true })
  watchLocalDatesValue() {
    this.fillLocalValueText();
  }

  async changeLocalValueByPreset(preset: DatePickerPresetModel) {
    this.localDatesValue = preset.dates;
    (this.$refs.menuDates as any).save(preset.dates);
    await this.$nextTick();
    this.setAccrualPeriod(preset.dates);
    this.menu = false;
  }

  fillLocalValueText() {
    if (this.localDatesValue.length) {
      this.dateFormatted = this.localDatesValue
        .map((it) => DateUtil.format(it, DateFormats.MONTH_PICKER_VIEW_FORMAT))
        .join(this.DATES_SEPARATOR);
    } else {
      this.dateFormatted = "";
    }
  }

  onLocalValueChange(value: Array<string>) {
    if (value && value.length === 2 && value[0] > value[1]) {
      value.sort();
    }

    this.fillLocalValueText();
    (this.$refs.menuDates as any).save(this.localDatesValue);
    this.setAccrualPeriod(value);
    this.menu = false;
  }

  clearLocalValue() {
    this.dateFormatted = "";
    this.localDatesValue = [];
    this.setAccrualPeriod(this.localDatesValue);
  }

  setAccrualPeriod(value: Array<string>) {
    const clonedValue = cloneDeep(this.value);

    clonedValue.accrualMonthFrom = value[0];
    clonedValue.accrualMonthTo = value[1];
    this.$emit("input", clonedValue);
  }
}
