










import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import DatesPicker from "@/shared/components/pickers/DatesPicker.vue";
import InvoiceFiltersModel from "@/accounting-portal/models/invoices/InvoiceFiltersModel";
import DateUtil from "@/shared/utils/DateUtil";

@Component({ components: { DatesPicker } })
export default class PaymentDateFilterVisualization extends Vue {
  @Prop() value!: InvoiceFiltersModel;

  readonly MAX_DATE = DateUtil.today();

  get required(): boolean {
    return !this.value.accrualMonthFrom && !this.value.accrualMonthTo;
  }

  get paymentDateFrom(): string {
    return this.value.paymentDateFrom ?? "";
  }

  get paymentDateTo(): string {
    return this.value.paymentDateTo ?? "";
  }

  get paymentDate(): Array<string> {
    return [this.paymentDateFrom, this.paymentDateTo];
  }

  set paymentDate(value: Array<string>) {
    const clonedValue = cloneDeep(this.value);

    clonedValue.paymentDateFrom = value[0];
    clonedValue.paymentDateTo = value[1];
    this.$emit("input", clonedValue);
  }
}
