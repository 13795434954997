















import { Component, Vue, Prop } from "vue-property-decorator";

import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import InvoiceFiltersModel from "@/accounting-portal/models/invoices/InvoiceFiltersModel";
import { CounterpartyTaxationType } from "@/accounting-portal/models/counterparties/CounterpartyTaxationType";
import { FilterPreviewId } from "@/shared/models";

@Component({ components: { CustomAutocomplete } })
export default class CounterpartiesFilterVisualization extends Vue {
  @Prop() value!: InvoiceFiltersModel;
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() isAttached!: boolean;

  readonly TAXATIONS = Object.values(CounterpartyTaxationType).map((value) => ({
    text: this.$lang(
      `accountingPortal.counterparties.taxation.${value.toLowerCase()}`
    ),
    value,
  }));

  get localValue(): InvoiceFiltersModel {
    return this.value;
  }

  set localValue(value: InvoiceFiltersModel) {
    this.$emit("input", value);
  }
}
