









































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import InvoiceTotalCard from "@/accounting-portal/components/invoices/InvoiceTotalCard.vue";
import InvoiceTotalChartCard from "@/accounting-portal/components/invoices/InvoiceTotalChartCard.vue";
import InvoicesResult from "@/accounting-portal/components/invoices/InvoicesResult.vue";
import InvoiceCalculationForm from "@/accounting-portal/components/invoices/InvoiceCalculationForm.vue";
import ExpansionPanelHeader from "@/reports/components/ExpansionPanelHeader.vue";
import InvoiceFilters from "@/accounting-portal/components/invoices/InvoiceFilters.vue";

import InvoiceResponseModel, {
  InvoiceChartType,
  InvoiceExpectedDateStatus,
  InvoicePaymentStatus,
  InvoiceStatus,
} from "@/accounting-portal/models/invoices/InvoiceResponseModel";
import InvoiceFiltersModel from "@/accounting-portal/models/invoices/InvoiceFiltersModel";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";
import EncodeUtil from "@/shared/utils/EncodeUtil";
import { DictionaryType } from "@/shared/models";

@Component({
  components: {
    InvoiceTotalCard,
    InvoiceTotalChartCard,
    InvoicesResult,
    InvoiceCalculationForm,
    ExpansionPanelHeader,
    InvoiceFilters,
  },
})
export default class InvoicesView extends mixins(AppSectionAccessMixin) {
  readonly InvoiceChartType = InvoiceChartType;
  readonly InvoicePaymentStatus = InvoicePaymentStatus;
  readonly InvoiceExpectedDateStatus = InvoiceExpectedDateStatus;
  isSideBarVisible = false;
  isGenerated = false;
  isFilterHorizontal = true;
  validFilters = false;
  horizontalFilters: Array<number> = [0];
  localInvoiceFilters = new InvoiceFiltersModel();

  get loading(): Array<InvoiceResponseModel> {
    return this.$store.state.invoiceStore.isInvoicesLoading;
  }

  get invoices(): Array<InvoiceResponseModel> {
    return this.$store.state.invoiceStore.invoices;
  }

  get invoicesByCurrency(): Record<string, Array<InvoiceResponseModel>> {
    return this.invoices.reduce(
      (
        result: Record<string, Array<InvoiceResponseModel>>,
        invoice: InvoiceResponseModel
      ) => {
        if (result[invoice.currencyCode]) {
          result[invoice.currencyCode].push(invoice);
        } else {
          result[invoice.currencyCode] = [invoice];
        }

        return result;
      },
      {}
    );
  }

  get openedInvoices(): Array<InvoiceResponseModel> {
    return this.invoices.filter(
      ({ status }: InvoiceResponseModel) => status === InvoiceStatus.OPEN
    );
  }

  get paidAndValidatedInvoices(): Array<InvoiceResponseModel> {
    return this.invoices.filter(
      ({ status }: InvoiceResponseModel) =>
        status === InvoiceStatus.PAID || status === InvoiceStatus.VALIDATED
    );
  }

  get paymentStatusChartData(): Array<Record<string, any>> {
    return [
      {
        status: InvoiceExpectedDateStatus.OK,
        value: this.paidAndValidatedInvoices.filter(
          ({ paymentStatus }) => paymentStatus === InvoicePaymentStatus.OK
        ).length,
      },
      {
        status: InvoicePaymentStatus.OVERPAY,
        value: this.paidAndValidatedInvoices.filter(
          ({ paymentStatus }) => paymentStatus === InvoicePaymentStatus.OVERPAY
        ).length,
      },
      {
        status: InvoicePaymentStatus.UNDERPAY,
        value: this.paidAndValidatedInvoices.filter(
          ({ paymentStatus }) => paymentStatus === InvoicePaymentStatus.UNDERPAY
        ).length,
      },
    ];
  }

  get expectedDateStatusChartData(): Array<Record<string, any>> {
    return [
      {
        status: InvoiceExpectedDateStatus.OK,
        value: this.openedInvoices.filter(
          ({ expectedDateStatus }) =>
            expectedDateStatus === InvoiceExpectedDateStatus.OK
        ).length,
      },
      {
        status: InvoiceExpectedDateStatus.OVERDUE,
        value: this.openedInvoices.filter(
          ({ expectedDateStatus }) =>
            expectedDateStatus === InvoiceExpectedDateStatus.OVERDUE
        ).length,
      },
    ];
  }

  get isInvoiceGenerated(): boolean {
    const { query } = this.$route;

    return query && !!Object.keys(query).length;
  }

  async created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("accountingPortal.invoices.title")
    );

    this.$store.dispatch("loadDictionaries", {
      dictionaryTypes: [DictionaryType.CURRENCIES],
    });
    this.initInvoices();
  }

  destroyed() {
    this.$store.commit("clearCounterparties");
    this.$store.commit("clearDictionaries");
  }

  async initInvoices() {
    const { query } = this.$route;

    this.$store.dispatch("loadShortCounterparties");
    this.$store.dispatch("loadShortBanks");

    if (query && Object.keys(query).length) {
      const response = await EncodeUtil.decompressRouteQuery(
        query.data as string
      );

      this.localInvoiceFilters = InvoiceFiltersModel.of(JSON.parse(response));
      this.$store.dispatch(
        "loadInvoices",
        InvoiceFiltersModel.ofRequest(this.localInvoiceFilters)
      );
    }
  }

  showInvoiceFormSideBar() {
    this.isSideBarVisible = true;
  }

  toggleHorizontalFilters() {
    this.horizontalFilters = this.horizontalFilters.length ? [] : [0];
  }

  async generateInvoice() {
    this.$store.dispatch(
      "loadInvoices",
      InvoiceFiltersModel.ofRequest(this.localInvoiceFilters)
    );

    const compressedQueryParams = await EncodeUtil.compressRouteQuery(
      JSON.stringify(this.localInvoiceFilters)
    );
    this.$router.replace({
      query: {
        data: compressedQueryParams,
      },
    });
  }
}
