import { CounterpartyTaxationType } from "@/accounting-portal/models/counterparties/CounterpartyTaxationType";

export default class InvoiceFiltersModel {
  constructor(
    public accrualMonthFrom?: string,
    public accrualMonthTo?: string,
    public counterpartyIds: Array<number> = [],
    public bankIds: Array<number> = [],
    public taxations: Array<CounterpartyTaxationType> = [],
    public paymentDateFrom?: string,
    public paymentDateTo?: string
  ) {}

  static of(model: InvoiceFiltersModel): InvoiceFiltersModel {
    return new InvoiceFiltersModel(
      model.accrualMonthFrom,
      model.accrualMonthTo,
      model.counterpartyIds,
      model.bankIds,
      model.taxations,
      model.paymentDateFrom,
      model.paymentDateTo
    );
  }

  static ofRequest(model: InvoiceFiltersModel): InvoiceFiltersRequestModel {
    return {
      accrualMonthFrom: model.accrualMonthFrom,
      accrualMonthTo: model.accrualMonthTo,
      ...(model.counterpartyIds?.length
        ? {
            counterpartyIds: model.counterpartyIds?.join(","),
          }
        : {}),
      ...(model.bankIds?.length
        ? {
            bankIds: model.bankIds?.join(","),
          }
        : {}),
      ...(model.taxations?.length
        ? {
            taxations: model.taxations?.join(","),
          }
        : {}),
      paymentDateFrom: model.paymentDateFrom,
      paymentDateTo: model.paymentDateTo,
    };
  }
}

interface InvoiceFiltersRequestModel {
  accrualMonthFrom?: string;
  accrualMonthTo?: string;
  counterpartyIds?: string;
  bankIds?: string;
  taxations?: string;
  paymentDateFrom?: string;
  paymentDateTo?: string;
}
