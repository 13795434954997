

































import { Component, Vue, Prop } from "vue-property-decorator";

import AccrualPeriodFilterVisualization from "./AccrualPeriodFilterVisualization.vue";
import CounterpartiesFilterVisualization from "./CounterpartiesFilterVisualization.vue";
import BanksFilterVisualization from "./BanksFilterVisualization.vue";
import TaxationsFilterVisualization from "./TaxationsFilterVisualization.vue";
import PaymentDateFilterVisualization from "./PaymentDateFilterVisualization.vue";

import { FilterColor, FilterPreviewId } from "@/shared/models";
import {
  DateFilterPreviewModel,
  FilterPreviewModel,
} from "@/shared/models/FilterPreviewModel";
import InvoiceFiltersModel from "@/accounting-portal/models/invoices/InvoiceFiltersModel";

@Component({
  components: {
    AccrualPeriodFilterVisualization,
    CounterpartiesFilterVisualization,
    BanksFilterVisualization,
    TaxationsFilterVisualization,
    PaymentDateFilterVisualization,
  },
})
export default class InvoiceFilters extends Vue {
  @Prop() value!: InvoiceFiltersModel;
  @Prop({ default: false }) horizontal!: boolean;
  @Prop() valid!: boolean;

  get localValid(): boolean {
    return this.valid;
  }

  set localValid(value: boolean) {
    this.$emit("update:valid", value);
  }

  get localValue(): InvoiceFiltersModel {
    return this.value;
  }

  set localValue(value: InvoiceFiltersModel) {
    this.$emit("input", value);
  }

  get filterPreviews(): any {
    return [
      new DateFilterPreviewModel(
        FilterPreviewId.ACCRUAL_DATE_VALUE,
        null,
        undefined,
        null
      ),
      new DateFilterPreviewModel(
        FilterPreviewId.PAYMENT_DATE,
        null,
        undefined,
        null
      ),
      new FilterPreviewModel(FilterPreviewId.COUNTERPARTY, FilterColor.PINK),
      new FilterPreviewModel(FilterPreviewId.BANK, FilterColor.PINK),
      new FilterPreviewModel(FilterPreviewId.TAXATION, FilterColor.GREY),
    ];
  }

  getComponent(filterPreviewId: FilterPreviewId): string | undefined {
    return new Map([
      [FilterPreviewId.COUNTERPARTY, "CounterpartiesFilterVisualization"],
      [FilterPreviewId.BANK, "BanksFilterVisualization"],
      [FilterPreviewId.ACCRUAL_DATE_VALUE, "AccrualPeriodFilterVisualization"],
      [FilterPreviewId.TAXATION, "TaxationsFilterVisualization"],
      [FilterPreviewId.PAYMENT_DATE, "PaymentDateFilterVisualization"],
    ]).get(filterPreviewId);
  }
}
