




















































































































































































































































































































import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import stringify from "csv-stringify/lib/sync";

import SidebarForm from "../SidebarForm.vue";
import InvoiceDetailsDialog from "./InvoiceDetailsDialog.vue";
import InvoiceEditDialog from "./InvoiceEditDialog.vue";
import InvoiceResponseModel, {
  InvoiceExpectedDateStatus,
  InvoicePaymentStatus,
  InvoiceResponseExportModel,
  InvoiceStatus,
} from "@/accounting-portal/models/invoices/InvoiceResponseModel";
import InvoiceUpdateRequestModel from "@/accounting-portal/models/invoices/InvoiceUpdateRequestModel";
import FileUtil from "@/shared/utils/FileUtil";
import DateUtil from "@/shared/utils/DateUtil";
import ValidUtil from "@/shared/utils/ValidUtil";
import { VueForm } from "@/shared/types/ExtendedVueType";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

@Component({
  components: {
    SidebarForm,
    InvoiceDetailsDialog,
    InvoiceEditDialog,
  },
})
export default class InvoicesTable extends mixins(AppSectionAccessMixin) {
  @Prop({ default: InvoiceStatus.OPEN }) tabName!: InvoiceStatus;

  @Ref("form") form!: VueForm;

  readonly DateUtil = DateUtil;
  readonly InvoiceStatus = InvoiceStatus;
  readonly REQUIRED_RULE = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly REQUIRED_NUMBER_RULE = [
    ValidUtil.requiredNumber(this.$lang("validation.required")),
    ValidUtil.minNumber(1, this.$lang("validation.min", 1)),
  ];
  search = "";
  itemsForExport: Array<InvoiceResponseExportModel> = [];
  isFileDownloading = false;
  overdueItemsVisible = false;
  finishDialogVisible = false;
  finishingInvoice = new InvoiceUpdateRequestModel();
  valid = false;
  menuPaymentDateVisible = false;
  invoiceDetailsVisible = false;
  selectedInvoice: InvoiceResponseModel | null = null;
  updatingInvoiceId: number | null = null;
  editFormVisible = false;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get headers(): Array<Record<string, any>> {
    return [
      {
        text: this.$lang("accountingPortal.invoices.number"),
        value: "id",
      },
      {
        text: this.$lang("accountingPortal.invoices.counterpartyName"),
        value: "counterpartyName",
      },
      {
        text: this.$lang("accountingPortal.invoices.accrualDate"),
        value: "accrualMonth",
      },
      {
        text: this.$lang("accountingPortal.invoices.expectedAmount"),
        value: "expectedAmount",
      },
      ...(this.tabName === InvoiceStatus.OPEN
        ? [
            {
              text: this.$lang("accountingPortal.invoices.expectedPaymentDate"),
              value: "expectedPaymentDate",
            },
            {
              text: this.$lang("accountingPortal.invoices.expectedDateStatus"),
              value: "expectedDateStatus",
            },
          ]
        : []),
      ...([InvoiceStatus.PAID, InvoiceStatus.VALIDATED].includes(this.tabName)
        ? [
            {
              text: this.$lang("accountingPortal.invoices.paymentDate"),
              value: "paymentDate",
            },
            {
              text: this.$lang("accountingPortal.invoices.paymentAmount"),
              value: "amount",
            },
            {
              text: this.$lang("accountingPortal.invoices.status"),
              value: "paymentStatus",
            },
          ]
        : []),
      ...([InvoiceStatus.OPEN, InvoiceStatus.PAID].includes(this.tabName)
        ? [
            {
              text: this.$lang("shared.actions"),
              align: "end",
              value: "actions",
              width: "10%",
              sortable: false,
            },
          ]
        : []),
    ];
  }

  get isLoading(): boolean {
    return this.$store.state.invoiceStore.isInvoicesLoading;
  }

  get invoices(): Array<InvoiceResponseModel> {
    return this.$store.state.invoiceStore.invoices.filter(
      ({ status, expectedDateStatus }: InvoiceResponseModel) => {
        return (
          status === this.tabName &&
          (!this.overdueItemsVisible ||
            expectedDateStatus === InvoiceExpectedDateStatus.OVERDUE)
        );
      }
    );
  }

  get exportable(): boolean {
    return !!this.invoices.length;
  }

  get csvHeaders(): Array<any> {
    return this.headers.map((it) => ({ key: it.value, header: it.text }));
  }

  get isInvoiceSaving(): boolean {
    return this.$store.state.invoiceStore.isInvoiceSaving;
  }

  @Watch("finishDialogVisible")
  watchFinishDialogVisible(value: boolean) {
    if (!value) {
      this.finishingInvoice = new InvoiceUpdateRequestModel();
      this.form.resetValidation();
    }
  }

  @Watch("editFormVisible")
  watchEditFormVisible(value: boolean) {
    if (!value) {
      this.invoiceDetailsVisible = true;
      this.selectedInvoice = this.invoices.find(
        (item) => item.id === this.selectedInvoice?.id
      ) as InvoiceResponseModel;
    }
  }

  showEditForm() {
    this.editFormVisible = true;
    this.invoiceDetailsVisible = false;
  }

  getFormattedAmount(amount: number, currencyCode: string): string {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode,
    }).format(amount);
  }

  handleClickByTableRow(item: InvoiceResponseModel) {
    if (item.status === InvoiceStatus.OPEN) {
      return;
    }

    this.invoiceDetailsVisible = true;
    this.selectedInvoice = InvoiceResponseModel.of(item);
  }

  getClassesForOverdueDate({
    expectedDateStatus,
  }: InvoiceResponseModel): string {
    return {
      OVERDUE: "red--text text--lighten-1 font-weight-bold",
      OK: "",
    }[expectedDateStatus];
  }

  getBackgroundForExpectedDateStatus({
    expectedDateStatus,
  }: InvoiceResponseModel): string {
    return {
      OVERDUE: "red lighten-1",
      OK: "green",
    }[expectedDateStatus];
  }

  getClassesForPaymentAmount({ paymentStatus }: InvoiceResponseModel): string {
    return {
      OVERPAY: "amber--text text--lighten-1 font-weight-bold",
      UNDERPAY: "red--text text--lighten-1 font-weight-bold",
      OK: "green--text font-weight-bold",
    }[paymentStatus];
  }
  // #64C84B - green

  getBackgroundForPaymentStatus({
    paymentStatus,
  }: InvoiceResponseModel): string {
    return {
      OVERPAY: "amber lighten-1",
      UNDERPAY: "red lighten-1",
      OK: "green",
    }[paymentStatus as InvoicePaymentStatus];
  }

  updateItemsForExport(items: Array<InvoiceResponseModel>) {
    this.itemsForExport = items.map((item) => ({
      ...item,
      amount: item.amount
        ? this.getFormattedAmount(item.amount, item.currencyCode)
        : undefined,
      expectedAmount: this.getFormattedAmount(
        item.expectedAmount,
        item.currencyCode
      ),
      expectedDateStatus: this.$lang(
        `accountingPortal.invoices.${item.expectedDateStatus.toLowerCase()}`
      ),
      paymentStatus: this.$lang(
        `accountingPortal.invoices.${item.paymentStatus.toLowerCase()}`
      ),
    }));
  }

  getCsvContent(delimiter?: string) {
    return stringify(this.itemsForExport, {
      header: true,
      columns: this.csvHeaders,
      delimiter: delimiter,
    });
  }

  exportToClipboard(delimiter?: string) {
    navigator.clipboard.writeText(this.getCsvContent(delimiter));
  }

  exportToCsvFile() {
    FileUtil.download(
      this.getCsvContent(),
      "Report.csv",
      FileUtil.CONTENT_TYPE_CSV
    );
  }

  exportToTsvFile() {
    FileUtil.download(
      this.getCsvContent("\t"),
      "Report.tsv",
      FileUtil.CONTENT_TYPE_TSV
    );
  }

  resyncInvoice() {
    console.log("resyncInvoice");
  }

  openFinishingDialog(item: InvoiceResponseModel) {
    this.updatingInvoiceId = item.id;
    this.finishDialogVisible = true;
  }

  async finishInvoice() {
    await this.$store.dispatch("finishInvoice", {
      id: this.updatingInvoiceId,
      invoice: this.finishingInvoice,
    });
    this.finishDialogVisible = false;
  }

  handleValidateInvoice(item: InvoiceResponseModel) {
    this.$store.dispatch("validateInvoice", item.id);
  }

  downloadPdf({ id, status }: InvoiceResponseModel) {
    this.$store.dispatch("downloadPdf", { id, status });
  }
}
